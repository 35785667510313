import { computed, Injectable } from "@angular/core";
import { CatalogItem } from "./catalog-item.data";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { HttpErrorService } from "../error/http-error.service";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";
import { catchError, combineLatest, map, of, shareReplay, switchMap } from "rxjs";
import { ResultArray } from "../result-array.data";
import { ECatalogItemType } from "./catalog-item-type.enum";
import { CatalogService } from "./catalog.service";

@Injectable({ providedIn: 'root' })
export class CatalogActivityService {
  private itemsUrl = `${environment.apiUri}/v1.0/catalogitems`;

  // Get activities
  private activitiesResult$ = combineLatest([
    toObservable(this.catalogService.selectedLanguage),
    this.catalogService.itemPublishedSubject
  ]).pipe(
    switchMap(([language]) => {
      let params = new HttpParams();
      params = params.append('pageIndex', 1);
      params = params.append('pageSize', 200);
      params = params.append('sortOrder', 'publication_desc');
      params = params.append('language', language);
      params = params.append('itemType', ECatalogItemType.Activity);

      return this.http.get<CatalogItem[]>(this.itemsUrl, {params: params});
    }),
    map(activities => ({ data: activities } as ResultArray<CatalogItem[]>)),
    shareReplay(1),
    catchError(err => {
      console.error(this.httpErrorService.formatError(err));
      return of({ data: [], error: this.httpErrorService.formatError(err) } as ResultArray<CatalogItem[]>);
    })
  );
  private activitiesResult = toSignal(
    this.activitiesResult$,
    { initialValue: ({ data: [] } as ResultArray<CatalogItem[]>) }
  );
  activities = computed(() => this.activitiesResult().data);
  activitiesError = computed(() => this.activitiesResult().error);

  constructor(
    private http: HttpClient,
    private httpErrorService: HttpErrorService,
    private catalogService: CatalogService
  ) { }
}
