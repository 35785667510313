import { HttpClient, HttpParams } from "@angular/common/http";
import { computed, Injectable, signal } from "@angular/core";
import { catchError, map, shareReplay, switchMap, tap } from "rxjs/operators";
import { of, Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { environment } from "src/environments/environment";
import { WonActivity } from "./won-activity.data";
import { HttpErrorService } from "../error/http-error.service";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";
import { ResultArray } from "../result-array.data";
import { ActivityQuery } from "./activity-query.data";
import { NewWonActivity } from "./new-won-activity.data";

@Injectable({
  providedIn: 'root'
})
export class WonActivityService {
  private wonActivityUrl = `${environment.apiUri}/v1.0/WonActivities`;
  private wonActivityV2Url = `${environment.apiUri}/v2.0/WonActivities`;
  private activitiesModifiedSubject = new BehaviorSubject<void>(undefined);

  private defaultQuery: ActivityQuery = {
    year: null,
    type: null
  };

  activityQuery = signal<ActivityQuery>(this.defaultQuery);

  // Get queried activities
  private activitiesResult$ = combineLatest([
    toObservable(this.activityQuery),
    this.activitiesModifiedSubject
  ]).pipe(
    switchMap(([searchQuery]) => {
      let params = new HttpParams();

      if (searchQuery.year) {
        params = params.append('year', searchQuery.year);
      }

      if (searchQuery.type) {
        params = params.append('type', searchQuery.type);
      }

      return this.http.get<WonActivity[]>(`${this.wonActivityUrl}`, {params: params});
    }),
    map(activities => ({ data: activities } as ResultArray<WonActivity[]>)),
    shareReplay(1),
    catchError(err => {
      console.error(this.httpErrorService.formatError(err));
      return of({ data: [], error: this.httpErrorService.formatError(err) } as ResultArray<WonActivity[]>);
    })
  );
  private activitiesResult = toSignal(
    this.activitiesResult$,
    { initialValue: ({ data: [] } as ResultArray<WonActivity[]>) }
  );
  activities = computed(() => this.activitiesResult().data);
  activitiesError = computed(() => this.activitiesResult().error);

  constructor(
    private http: HttpClient,
    private httpErrorService: HttpErrorService
  ) { }

  winActivity(activity: NewWonActivity): Observable<WonActivity> {

    return this.http.post<WonActivity>(this.wonActivityV2Url, activity).pipe(
      tap(value => {
        this.activitiesModifiedSubject.next();
      }),
      catchError(this.httpErrorService.handleError)
    );
  }
}
